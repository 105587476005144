.checkout-form {
    max-width: 400px;
    width: 100%;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f2f2f2;
  }
  
  .input-container {
    margin-bottom: 10px;
  }
  
  .label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .card-element {
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .pay-button {
    display: block;
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px;
    font-weight: bold;
    background-color: #0070f3;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .pay-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pay-button:hover:not(:disabled) {
    background-color: #006ae6;
  }
  